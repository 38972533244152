import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { Input } from '../Input';

export default function PasswordInput({
  value,
  onChange,
  label,
  placeholder,
  className,
}: {
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  placeholder: string;
  className?: string;
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="relative">
      <Input
        className={className}
        value={value}
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        type={showPassword ? 'text' : 'password'}
      />
      <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className="absolute right-3 top-3 focus:outline-none z-10"
        style={{ pointerEvents: 'auto' }}
      >
        {showPassword ? (
          <EyeOffIcon className="w-4" />
        ) : (
          <EyeIcon className="w-4" />
        )}
      </button>
    </div>
  );
}
